// import React from 'react';
// import './Profile.scss';

// const Profile = () => {
//   return (
//     <section id="profile" className="profile">
//       <h2 className="profile__title">ABOUT ME</h2>

//       <p>I first began my programming journey in high school around 2015 as a self-taught Python programmer,
//         building simple scripts to automate tasks. Shortly after, I got into building and programming racing
//         quadcopters, which sparked my passion for engineering and software. When I entered university, my focus
//         expanded to programming industrial robots and manufacturing lines, further deepening my technical expertise.
//       </p>

//       <p>Over time, I transitioned into web development, building scalable applications, and diving into machine learning,
//         where I've worked on projects like wildfire prediction models and interactive 3D web applications. These days, I
//         enjoy creating software that not only functions seamlessly but also combines design and engineering to provide
//         intuitive user experiences.
//       </p>

//       <p>When I'm not coding, you can find me hiking, skiing, or researching mechanical watches I can't afford.</p>
//     </section>
//   );
// };

// export default Profile;

import React from 'react';
import './Profile.scss';

const Profile = ({ id }) => {
	return (
		<section id={id} className="profile">
			<div className="code-content">
				<p className="code-comment">// About me</p>
				<p><span className="keyword">const</span> <span className="variable">profile</span> = <span className="brace-1">{'{'}</span></p>
				<div className="profile__content">
					<div className="profile__details">
						<p>
							I first began my programming journey in high school around 2015 as a self-taught Python programmer,
							building simple scripts to automate tasks. Shortly after, I got into building and programming racing
							quadcopters, which sparked my passion for engineering and software. When I entered university, my focus
							expanded to programming industrial robots and manufacturing lines, further deepening my technical expertise.
						</p>

						<p>
							Over time, I transitioned into web development, building scalable applications, and diving into machine learning,
							where I've worked on projects like wildfire prediction models and interactive 3D web applications. These days, I
							enjoy creating software that not only functions seamlessly but also combines design and engineering to provide
							intuitive user experiences.
						</p>

						<p>If I'm not coding, I'm likely outdoors hiking, skiing, or obsessively reading about mechanical watches I'll never own.</p>
					</div>
				</div>
				<p><span className="brace-1">{'}'}</span>;</p>
			</div>
		</section>
	);
};

export default Profile;
