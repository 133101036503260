import React, { useState } from 'react';
import Card from '../../components/Card/Card';
import './Experience.scss';

const Experience = () => {
  const [hoveredCard, setHoveredCard] = useState(null);

  const experiences = [
    {
      title: 'Freelance Developer',
      date: 'APR 2024 — PRESENT',
      description: 'Built and maintained dynamic web applications using React, Node.js, and JavaScript, ensuring seamless user experiences across platforms.',
      tags: ['React', 'Node.js', 'JavaScript', 'HTML & CSS', 'Python', 'Java', 'C++'],
    },
    {
      title: 'Consultant Intern · Deloitte',
      date: 'JUL 2022 — AUG 2022',
      description: 'Devised and delivered innovative fire-fighting and surveying drone solutions to international clients. Programmed and trained a machine learning model to detect fires from thermal imaging data',
      tags: ['TensorFlow', 'Keras', 'Python', 'SQL'],
    },
    {
      title: 'Web Developer · Eye Consultants',
      date: 'APR 2021 — JUN 2022',
      description: 'Engineered and maintained backend systems. Revamped appointment booking process, introducing process automation and a user-focused design that boosted operational efficiency and enhanced client satisfaction.',
      tags: ['JavaScript', 'Node.js', 'Express', 'MongoDB'],
    },
  ];

  return (
    <section id="experience" className="experience">
      <div className="code-content">
        <p className="code-comment">// My experience</p>
        <p><span className="keyword">const</span> <span className="variable">experience</span> = <span className="brace-2">[</span></p>
        <div className="experience__list">
          {experiences.map((exp, index) => (
            <Card
              key={index}
              title={exp.title}
              subtitle={exp.subtitle}
              date={exp.date}
              description={exp.description}
              tags={exp.tags}
              link={exp.link}
              isDimmed={hoveredCard !== null && hoveredCard !== index}
              onHover={() => setHoveredCard(index)}
              onLeave={() => setHoveredCard(null)}
              isLast={index === experiences.length - 1}
            />
          ))}
        </div>
        <p><span className="brace-2">]</span>;</p>
      </div>
    </section>
  );
};

export default Experience;
