import React from 'react';
import Sidebar from './components/Sidebar/Sidebar';
import Profile from './pages/Profile/Profile';
import Projects from './pages/Projects/Projects';
import Experience from './pages/Experience/Experience';
import Education from './pages/Education/Education';
import CursorReactiveBackground from './components/CursorReactiveBackground/CursorReactiveBackground';
import './App.scss';

function App() {
	return (
		<div className="App">
			<CursorReactiveBackground />
			<div className="App__container">
				<div className="App__sidebar">
					<Sidebar />
				</div>
				<div className="App__content">
					<Profile id="profile" />
					<Experience id="experience" />
					<Projects id="projects" />
					<Education id="education" />
				</div>
			</div>
		</div>
	);
}

export default App;
