import React, { useState } from 'react';
import Card from '../../components/Card/Card';
import './Projects.scss';

const Projects = () => {
  const [hoveredCard, setHoveredCard] = useState(null);

  const projects = [
    {
      title: 'Machine Learning Engineer · FireForecaster',
      date: 'FEB 2023 — PRESENT',
      description: 'Developed and trained a machine learning model to predict wildfires by analyzing lightning data and real-time weather parameters. Achieved a prediction accuracy exceeding 75%.',
      tags: ['Machine Learning', 'Python', 'Data Analysis'],
    },
    {
      title: 'Full Stack Developer · VisionCubed',
      date: 'MAY 2024 — JUN 2024',
      description: 'Developed a web app that uses computer vision techniques to scan and digitize a physical Rubik\'s Cube. Utilized Three.js for interactive 3D visualization.',
      tags: ['React', 'Three.js', 'Node.js'],
    },
  ];
  
  return (
    <section id="projects" className="projects">
      <div className="code-content">
        <p className="code-comment">// My notable projects</p>
        <p><span className="keyword">const</span> <span className="variable">projects</span> = <span className="brace-2">[</span></p>
        <div className="projects__list">
          {projects.map((project, index) => (
            <Card
              key={index}
              title={project.title}
              subtitle={project.subtitle}
              date={project.date}
              description={project.description}
              tags={project.tags}
              link={project.link}
              isDimmed={hoveredCard !== null && hoveredCard !== index}
              onHover={() => setHoveredCard(index)}
              onLeave={() => setHoveredCard(null)}
              isLast={index === projects.length - 1}
            />
          ))}
        </div>
        <p><span className="brace-2">]</span>;</p>
      </div>
    </section>
  );
};

export default Projects;
