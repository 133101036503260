import React, { useEffect } from 'react';
import './CursorReactiveBackground.scss';

const CursorReactiveBackground = () => {
  useEffect(() => {
    const handleMouseMove = (e) => {
      const x = e.clientX / window.innerWidth;
      const y = e.clientY / window.innerHeight;
      
      document.documentElement.style.setProperty('--cursor-x', x);
      document.documentElement.style.setProperty('--cursor-y', y);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return <div className="cursor-reactive-background"></div>;
};

export default CursorReactiveBackground;