import React, { useState, useEffect, useRef } from 'react';
import './Sidebar.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faChevronRight, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { Typed } from 'react-typed';
import resumePDF from '../../assets/files/Youssef El Khashab - Software Engineer Resume.pdf';

const Sidebar = () => {
    const [activeSection, setActiveSection] = useState('profile');
    const [isScrolling, setIsScrolling] = useState(false);
    const scrollTimeout = useRef(null);
    const el = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (isScrolling) return;

            const sections = ['profile', 'experience', 'projects', 'education'];
            let currentSection = sections[0];

            for (const section of sections) {
                const element = document.getElementById(section);
                if (element && element.getBoundingClientRect().top <= 100) {
                    currentSection = section;
                }
            }

            setActiveSection(currentSection);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isScrolling]);

    useEffect(() => {
        const options = {
            strings: [
                'Software Engineer',
                'Full Stack Developer',
                'Machine Learning Enthusiast',
                'Problem Solver',
                'Continuous Learner'
            ],
            typeSpeed: 50,
            backSpeed: 30,
            backDelay: 2000,
            startDelay: 500,
            loop: true,
            showCursor: true,
            cursorChar: '|',
        };

        const typed = new Typed(el.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    const scrollToSection = (sectionId) => {
        setIsScrolling(true);
        const section = document.getElementById(sectionId);
        if (section) {
            const isDesktop = window.innerWidth >= 1024; // Adjust this breakpoint as needed
            const offset = isDesktop ? 96 : 0; // 6rem = 96px, adjust if your rem size is different

            const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = sectionPosition - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });

            setActiveSection(sectionId);

            // Clear any existing timeout
            if (scrollTimeout.current) clearTimeout(scrollTimeout.current);

            // Set a new timeout
            scrollTimeout.current = setTimeout(() => {
                setIsScrolling(false);
            }, 1000);
        }
    };

    return (
        <aside className="sidebar">
            <section className="sidebar__header">
                <h1 className="sidebar__name">Youssef <nobr>El Khashab</nobr></h1>
                <div className="sidebar__title-container">
                    <span className="sidebar__title" ref={el}></span>
                </div>
            </section>
            <nav className="sidebar__nav">
                <ul>
                    {['profile', 'experience', 'projects', 'education'].map((section) => (
                        <li key={section} className={activeSection === section ? 'active' : ''}>
                            <FontAwesomeIcon icon={faChevronRight} className="chevron-icon" />
                            <button onClick={() => scrollToSection(section)}>
                                {section.charAt(0).toUpperCase() + section.slice(1)}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
            <section className="sidebar__contact">
                <ul className="sidebar__links">
                    <li>
                        <a href="http://github.com/yelkhashab" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faGithub} />
                        </a>
                    </li>
                    <li>
                        <a href="http://linkedin.com/in/yelkhashab" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                    </li>
                    <li>
                        <a href="mailto:yelkhashab@outlook.com">
                            <FontAwesomeIcon icon={faEnvelope} />
                        </a>
                    </li>
                    <li>
                        <a 
                            href={resumePDF}
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faFileAlt} />
                        </a>
                    </li>
                </ul>
            </section>
        </aside>
    );
};

export default Sidebar;
