import React, { useState } from 'react';
import Card from '../../components/Card/Card';
import './Education.scss';

const Education = () => {
    const [hoveredCard, setHoveredCard] = useState(null);

    const education = [
        {
            title: `The University of Texas at Austin · Master’s of Data Science`,
            date: 'JAN 2025 — PRESENT',
            description: `Incoming Master's student with a focus on machine learning, data modelling and artificial intelligence.`,
            tags: ['Machine Learning', 'Advanced Predictive Modelling', `Data Visualization`, `Deep Learning`, `Natural Language Processing`],
        },
        {
            title: 'BrainStation · Software Engineering Bootcamp',
            date: 'JAN 2024 — APR 2024',
            description: 'Comprehensive 12 week software engineering bootcamp with a focus on web development and agile development.',
            tags: ['Top of Cohort', 'Return offer as TA']
        },
        {
            title: `University of British Columbia · Bachelor's in Mechanical Engineering`,
            subtitle: ['Mechatronics Option', 'Minor in Computer Science'],
            date: 'SEP 2018 — JUN 2023',
            tags: ['3.8 GPA', 'Graduated with Distinction', `Dean's Honour List`],
        }
    ];

    return (
        <section id="education" className="education">
            <div className="code-content">
                <p className="code-comment">// My educational background</p>
                <p><span className="keyword">const</span> <span className="variable">education</span> = <span className="brace-2">[</span></p>
                <div className="education__list">
                    {education.map((edu, index) => (
                        <Card
                            key={index}
                            title={edu.title}
                            subtitle={edu.subtitle}
                            date={edu.date}
                            description={edu.description}
                            tags={edu.tags}
                            isDimmed={hoveredCard !== null && hoveredCard !== index}
                            onHover={() => setHoveredCard(index)}
                            onLeave={() => setHoveredCard(null)}
                            isLast={index === education.length - 1}
                        />
                    ))}
                </div>
                <p><span className="brace-2">]</span>;</p>
            </div>
        </section>
    );
};

export default Education;
